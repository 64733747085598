<template>
  <v-container>
    <language-switcher floating />
    <validation-observer v-slot="{ valid }">
      <v-form @submit.prevent="login">
        <div class="login">
          <h1>{{ $t("welcome") }}!</h1>
          <p>{{ $t("welcome-text") }}</p>
          <v-row no-gutters>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('email').toLowerCase()"
                :rules="{ required: true, email: true }"
              >
                <v-text-field
                  v-model="request.email"
                  outlined
                  :label="$t('email')"
                  :error-messages="errors"
                >
                  <template #[`prepend-inner`]>
                    <v-img max-width="22px" :src="require(`@/assets/message.svg`)" />
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('password').toLowerCase()"
                :rules="{ required: true, min: 6 }"
              >
                <v-text-field
                  v-model="request.password"
                  outlined
                  :label="$t('password')"
                  :error-messages="errors"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                >
                  <template #[`prepend-inner`]>
                    <v-img
                      max-width="22px"
                      :src="require(`@/assets/password-gray.svg`)"
                    />
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="text-right mt-10 pt-10">
              <v-btn color="primary" type="submit" :disabled="!valid" elevation="0" block>
                {{ $t("login") }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </validation-observer>
  </v-container>
</template>

<script>
import LanguageSwitcher from "../components/LanguageSwitcher";

export default {
  mixins: [],
  components: {
    LanguageSwitcher,
  },
  data: () => ({
    showPassword: false,
    request: {
      email: "",
      password: "",
    },
  }),
  computed: {},
  created: function () {},
  methods: {
    async login() {
      try {
        await this.$store.dispatch("auth/login", this.request);

        await this.$router.push({ name: "dashboard" });
      } catch (error) {
        await this.$store.dispatch("notificator/errorResponse", error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;

  .login {
    width: 342px;

    h1 {
      color: #1d3557;
      font-weight: 900;
      font-size: 39px;
      line-height: 120%;
    }

    .v-btn {
      font-weight: 500;
      font-size: 18px;
    }
  }
}
</style>
